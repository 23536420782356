<template>
    <div class="time_wrapper">
        <div 
            v-if="mainLoading" 
            class="flex justify-center">
            <a-spin />
        </div>
        <template v-else>
            <template v-if="pageConfig">
                <a-button 
                    v-if="pageConfig.headerButtons && isOperator"
                    type="ui"
                    :size="pageConfig.headerButtons.size"
                    class="mb-4"
                    :class="{'w-full': isMobile}"
                    @click="visible = true"
                    :icon="pageConfig.headerButtons.icon">
                    {{ pageConfig.headerButtons.text }}
                </a-button>
                <div 
                    v-if="timeList.results && timeList.results.length" >
                    <component 
                        :is="viewComponent"
                        :pageConfig="pageConfig"
                        :timeList="timeList"
                        :user="user"
                        :task="task"
                        @editTime="editTime"
                        @deleteTime="deleteTime">
                    </component>
                </div>
                <infinite-loading
                    v-if="timeList.next"
                    @infinite="getTimeList"
                    :identifier="task.id"
                    v-bind:distance="10">
                    <div slot="spinner"><a-spin /></div>
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>

                <div v-if="listEmpty">
                    <a-empty description="Записи отсутствуют" />
                </div>

                <a-modal
                    :title="pageConfig.modalConfig.label"
                    :zIndex="9999"
                    destroyOnClose
                    :visible="visible"
                    @cancel="closeModal()">
                    <a-form-model
                        ref="workTimeForm"
                        class="work_time_form"
                        :model="form"
                        :rules="rules">
                        <a-form-model-item
                            v-if="pageConfig.form.description"
                            class="mb-2"
                            prop="description">
                            <a-textarea
                                v-model="form.description"
                                placeholder="Описание работы"
                                :auto-size="{ minRows: 2, maxRows: 6 }"/>
                        </a-form-model-item>
                        <div class="grid grid-cols-2 gap-2">
                            <a-form-model-item
                                v-if="pageConfig.form.work_type"
                                label="Тип работ"
                                class="mb-4"
                                prop="work_type">
                                <a-select 
                                    v-model="form.work_type"
                                    :loading="typeListLoading"
                                    defaultActiveFirstOption
                                    :getPopupContainer="getPopupContainer"
                                    size="large">
                                    <a-select-option 
                                        v-for="item in typeList" 
                                        :key="item.id" 
                                        :value="item.code">
                                        {{ item.string_view }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                            <a-form-model-item
                                v-if="pageConfig.form.date"
                                label="Дата"
                                class="mb-4"
                                prop="date">
                                <Datepicker 
                                    v-model="form.date" 
                                    size="large"
                                    mask="00.00.0000"
                                    :showTime="false"
                                    dateFormat="DD.MM.YYYY"
                                    :getCalendarContainer="getCalendarContainer" />
                            </a-form-model-item>
                        </div>
                        <div class="grid grid-cols-2 gap-2">
                            <a-form-model-item
                                v-if="pageConfig.form.hours"
                                label="Количество"
                                prop="hours">
                                <a-input-number 
                                    v-model="form.hours" 
                                    :min="0.1"
                                    :step="0.1"
                                    class="w-full"
                                    @pressEnter="formSubmit()"
                                    size="large"
                                    :max="99" />
                            </a-form-model-item>
                            <a-form-model-item
                                v-if="pageConfig.form.measure_unit"
                                label="Единица измерения"
                                prop="measure_unit">
                                <a-select 
                                    v-model="form.measure_unit"
                                    :loading="edListLoading"
                                    defaultActiveFirstOption
                                    :getPopupContainer="getPopupContainer"
                                    size="large">
                                    <a-select-option 
                                        v-for="item in edList" 
                                        :key="item.id" 
                                        :value="item.code">
                                        {{ item.string_view }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </div>
                    </a-form-model>
                    <template slot="footer">
                        <div class="flex justify-end">
                            <a-button
                                v-if="pageConfig.modalConfig.okButton"
                                :block="isMobile"
                                :size="pageConfig.modalConfig.okButton.size"
                                :type="pageConfig.modalConfig.okButton.type"
                                :loading="loading"
                                class="mr-1"
                                @click="formSubmit()">
                                {{ edit ? "Сохранить" : "Добавить" }}
                            </a-button>
                            <a-button 
                                v-if="pageConfig.modalConfig.cancelButton"
                                :block="isMobile"
                                :size="pageConfig.modalConfig.cancelButton.size"
                                :type="pageConfig.modalConfig.cancelButton.type"
                                @click="closeModal()">
                                {{ pageConfig.modalConfig.cancelButton.text }}

                            </a-button>
                        </div>
                    </template>
                </a-modal>
            </template>
        </template>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import Datepicker from '@apps/Datepicker'
export default {
    components: {
        InfiniteLoading,
        Datepicker
    },
    props: {
        task: {
            type: Object,
            required: true
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isOperator() {
            if(this.user.id === this.task.operator.id) {
                return true
            } else if(this.task.task_type === 'interest' && this.task.visors.length !== 0) {
                return this.task.visors.some(visor => {
                    return this.user.id === visor.id
                })
            } else {
                return false
            }
        },
        workTimeSettings() {
            return this.$store.state.task.workTimeSettings
        },
        pageConfig() {
            if(this.workTimeSettings?.[this.task.task_type])
                return this.workTimeSettings[this.task.task_type]
            else
                return null
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        viewComponent() {
            const componentName = this.isMobile ? 'TaskWorkTimeList' : 'TaskWorkTimeTable' 
            return () => import(`./${componentName}`)

        }
    },
    data() {
        return {
            visible: false,
            locale,
            form: {
                task: this.task.id,
                work_type: null,
                description: '',
                date: null,
                hours: 1.0,
                measure_unit: null
            },
            typeList: [],
            edList: [],
            timeListLoading: false,
            mainLoading: false,
            loading: false,
            listEmpty: false,
            typeListLoading: false,
            edListLoading: false,
            edit: false,
            timeList: {
                results: [],
                count: 0,
                next: true
            },
            page: 0,
            rules: {
                work_type: [
                    { 
                        required: true,
                        message: this.$t('field_required'), 
                        trigger: 'change' 
                    }
                ],
                hours: [
                    { 
                        required: true,
                        message: this.$t('field_required'), 
                        trigger: 'change',
                        type: 'number'
                    }
                ],
                description: [
                    { 
                        max: 511,
                        message: this.$t('required_sym', { sym: 511 }), 
                        trigger: 'change'
                    }
                ],
                measure_unit: [
                    { 
                        required: true,
                        message: this.$t('field_required'), 
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    watch: {
        visible(val) {
            if(val && !this.typeList?.length) {
                this.getTypeList()
                this.getEdList()
            }
            if(val && !this.edit) {
                this.form.date = this.$moment()
            }
        }
    },
    created() {
        this.getSettings()
    },
    methods: {
        async getSettings() {
            try {
                this.mainLoading = true
                await this.$store.dispatch('task/getWorkTimeSettings', {
                    task_type: this.task.task_type
                })
            } catch(e) {
                console.log(e)
            } finally {
                this.mainLoading = false
            }
        },
        getCalendarContainer() {
            return document.querySelector('.work_time_form')
        },
        listDelete(item) {
            this.timeList.count -= 1
            const index = this.timeList.results.findIndex(f => f.id === item.id)
            if(index !== -1)
                this.timeList.results.splice(index, 1)
        },
        listUpdate(item) {
            const index = this.timeList.results.findIndex(f => f.id === item.id)
            if(index !== -1)
                this.$set(this.timeList.results, index, item)
        },
        editTime(item) {
            this.edit = true
            this.form = {
                work_type: item.work_type.code,
                description: item.description,
                hours: Number(item.hours),
                date: item.date ? this.$moment(item.date) : null,
                id: item.id,
                measure_unit: item.measure_unit?.code || "hours"
            }
            this.visible = true
        },
        deleteTime(item) {
            this.$confirm({
                title: 'Предупреждение',
                content: 'Вы действительно хотите удалить эту запись?',
                zIndex: 9999,
                cancelText: 'Закрыть',
                okText: 'Удалить',
                okType: 'danger',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post('/table_actions/update_is_active/', [{ id: item.id, is_active: false }])
                            .then(() => {
                                this.$message.success('Запись удалена')
                                this.listDelete(item)
                                resolve()
                            })
                            .catch((e) => {
                                console.log(e)
                                reject()
                            })
                    })
                },
                onCancel() {}
            })
        },
        descSubstr(text) {
            if(text && text.length > 60)
                return text.substr(0, 60) + '...'
            else
                return text
        },
        descLength(text) {
            if(text && text.length > 60)
                return true
            else
                return false
        },
        getPopupContainer() {
            return document.querySelector('.work_time_form')
        },
        getPopupContainer2() {
            return document.querySelector('.task_body_wrap')
        },
        async getTimeList($state) {
            if(!this.timeListLoading && this.timeList.next) {
                try {
                    this.timeListLoading = true
                    this.page += 1
                    const { data } = await this.$http.get('/tasks/time_tracking/', {
                        params: {
                            task: this.task.id,
                            page: this.page,
                            page_size: 20
                        }
                    })
                    this.timeList.count = data.count
                    this.timeList.next = data.next

                    if(data?.results?.length)
                        this.timeList.results = this.timeList.results.concat(data.results)
                    else if(this.page === 1) {
                        this.listEmpty = true
                    }

                    if(this.timeList.next)
                        $state.loaded()
                    else
                        $state.complete()
                } catch(e) {
                    console.log(e)
                } finally {
                    this.timeListLoading = false
                }
            }
        },
        async getTypeList() {
            try {
                this.typeListLoading = true
                const { data } = await this.$http.get('/app_info/select_list/', {
                    params: {
                        model: 'tasks.TaskWorkTypeModel',
                        filters: {
                            work_type_task_type__task_type_id: this.task.task_type
                        }
                    }
                })
                if(data?.selectList?.length) {
                    this.typeList = data.selectList
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.typeListLoading = false
            }
        },
        async getEdList() {
            try {
                this.edListLoading = true
                const { data } = await this.$http.get('/app_info/select_list/', {
                    params: {
                        model: 'catalogs.MeasureUnitModel'
                    }
                })
                if(data?.selectList?.length) {
                    this.edList = data.selectList
                    if(!this.form.measure_unit && this.pageConfig?.defaultValue?.measure_unit) {
                        this.form.measure_unit = this.pageConfig.defaultValue.measure_unit
                    }
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.edListLoading = false
            }
        },
        closeModal() {
            this.visible = false
            this.edit = false
            this.form = {
                task: this.task.id,
                work_type: null,
                description: '',
                date: null,
                hours: 1.0,
                measure_unit: this.pageConfig?.defaultValue?.measure_unit ? this.pageConfig.defaultValue.measure_unit : null
            }
        },
        async changeStatus(status) {
            try {
                await this.$store.dispatch('task/changeStatus', {
                    task: this.task, 
                    status
                })
            } catch(e) {
                this.$message.error(this.$t('error'))
                console.log(e, 'changeStatus')
            }
        },
        formSubmit() {
            this.$refs.workTimeForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        let form = JSON.parse(JSON.stringify(this.form))

                        if(form.date)
                            form.date = this.$moment(form.date).format('YYYY-MM-DD')

                        if(this.edit) {
                            const { data } = await this.$http.put(`/tasks/time_tracking/${this.form.id}/`, form)
                            if(data) {
                                this.$message.success('Запись обновлена')
                                this.closeModal()
                                this.listUpdate(data)
                            }
                        } else {
                            const { data } = await this.$http.post('/tasks/time_tracking/', form) 
                            if(data) {
                                this.$message.success('Запись добавлена')
                                this.closeModal()
                                this.timeList.results.unshift(data)
                                this.timeList.count += 1

                                if(this.listEmpty)
                                    this.listEmpty = false

                                if(data?.status) {
                                    let status = data.status
                                    this.$store.dispatch('task/updateStatus', {
                                        task: this.task, 
                                        status
                                    })
                                    // await this.changeStatus(data.status)
                                }
                            }
                        }
                    } catch(error) {
                        this.$message.error('Ошибка')
                        console.log(error)
                    } finally {
                        this.loading = false
                    }
                } else {
                    this.$message.warning(this.$t('fill_required_fields'))
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
.time_list{
    .col{
        padding: 10px 0;
        display: flex;
        align-items: center;
        &.hours{
            text-align: center;
            justify-content: center;
        }
        &.desc{
            padding-right: 10px;
            span{
                word-break: break-word;
            }
        }
        &.action{
            display: flex;
            justify-content: flex-end;
        }
    }
    .item_head{
        font-weight: 600;
    }
    .item{
        grid-template-columns: 130px 1fr 1fr 1fr 1fr 60px;
        &:not(:last-child){
            border-bottom: 1px solid var(--borderColor);
        }
    }
}

</style>